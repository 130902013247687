import { useController, useWatch } from 'react-hook-form';

export const useStepFourInputs = (control, isValidate) => {
    console.log(isValidate, 'isValidate');

    const day = useWatch({ control, name: 'day' });
    const hours = useWatch({ control, name: 'hours' });
    const minutes = useWatch({ control, name: 'minutes' });
    const { field: expirationTimeField } = useController({
        name: 'expirationTime',
        control,
        rules: {
            required: isValidate ? 'Please enter a date' : false,
        },
    });

    const { field: dayField } = useController({
        name: 'day',
        control,
        rules: {
            required: isValidate
                ? !hours && !minutes
                    ? 'Please fill at least one of day, hours, or minutes'
                    : false
                : false,
        },
    });

    const { field: hoursField } = useController({
        name: 'hours',
        control,
        rules: {
            required: isValidate
                ? !day && !minutes
                    ? 'Please fill at least one of day, hours, or minutes'
                    : false
                : false,
        },
    });

    const { field: minutesField } = useController({
        name: 'minutes',
        control,
        rules: {
            required: isValidate
                ? !day && !hours
                    ? 'Please fill at least one of day, hours, or minutes'
                    : false
                : false,
        },
    });

    return { expirationTimeField, dayField, hoursField, minutesField };
};
