import { useEffect, useState } from 'react';
import { useController, useFieldArray, useWatch } from 'react-hook-form';
import { getTokensForBudget } from '../../../apis/general/general';

export const useStepSixInputs = (control, isBriefRequired, getValues, setError, clearErrors) => {
    const [tokens, setTokens] = useState([]);

    useEffect(function () {
        const fetchData = async () => {
            try {
                const data = await getTokensForBudget();
                const tokensToSet = data?.filter((token) => token.chainId);

                setTokens(tokensToSet);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const { field: withRewardTokenField } = useController({
        name: 'withRewardToken',
        control,
    });
    // selfFunding
    const { field: selfFundingField } = useController({
        name: 'selfFunding',
        control,
    });
    const safeGetValues = getValues || (() => ({}));
    const { field: distributionModeField } = useController({
        name: 'distributionMode',
        control,
    });
    const { field: tokenField } = useController({
        name: 'token',
        control,
        rules: { required: withRewardTokenField?.value ? 'Please choose a token' : false },
    });

    const {
        fields: ranksField,
        append: appendRank,
        prepend,
        remove: removeRank,
        swap,
        // move,
        // remove,
    } = useFieldArray({
        name: 'ranks',
        control,
    });
    const watchedRanks = useWatch({ name: 'ranks', control });

    const { field: totalRewardAmountField } = useController({
        name: 'totalRewardAmount',
        control,
        rules: {
            required: withRewardTokenField?.value ? 'Please enter an amount' : false,
            validate: (value) => {
                if (!withRewardTokenField?.value) return true;
                if (distributionModeField.value === 'Rank based distribution') {
                    const ranksAmount = Array.isArray(watchedRanks)
                        ? watchedRanks
                              .filter((rank) => rank.amount)
                              .map((c) => Number(c.amount))
                              .reduce((acc, curr) => acc + curr, 0)
                        : 0;

                    if (ranksAmount > Number(value)) return 'Please add more fund';
                    return true;
                }
                return true;
            },
        },
    });
    const { field: noOfWinnersField } = useController({
        name: 'noOfWinners',
        control,
        rules: {
            required: withRewardTokenField?.value ? 'Please enter number of winners' : false,
            min: {
                value: 1,
                message: 'Min value is 1',
            },
        },
    });

    const { field: validationTillDateField } = useController({
        name: 'validationTillDate',
        control,
        rules: {
            required:
                selfFundingField?.value === 'Yes, we will fund the bounty'
                    ? withRewardTokenField?.value
                        ? 'Please enter a date'
                        : false
                    : false,
            // validate: (value) => {
            //     if (!withRewardTokenField?.value) return true;
            //     if (!expirationTimeField.value) {
            //         return 'Please select the first date before selecting the second date.';
            //     }

            //     return true;
            // },
        },
    });

    const { field: daySubField } = useController({
        name: 'daySub',
        control,
        rules: {
            required:
                selfFundingField?.value === 'Yes, we will fund the bounty'
                    ? !withRewardTokenField?.value
                        ? 'Day is required'
                        : false
                    : false,
        },
    });
    const { field: hoursSubField } = useController({
        name: 'hoursSub',
        control,
        rules: {
            required:
                selfFundingField?.value === 'Yes, we will fund the bounty'
                    ? !withRewardTokenField?.value
                        ? 'Hours are required'
                        : false
                    : false,
        },
    });
    const { field: minutesSubField } = useController({
        name: 'minutesSub',
        control,
        rules: {
            required:
                selfFundingField?.value === 'Yes, we will fund the bounty'
                    ? !withRewardTokenField?.value
                        ? 'Minutes are required'
                        : false
                    : false,
        },
    });

    const { field: passThresholdField } = useController({
        name: 'passThreshold',
        control,
        rules: {
            required: 'Please enter pass threshold',
            max: {
                value: 100,
                message: 'Max value is 100',
            },
        },
    });

    const { field: isAttestableField } = useController({
        name: 'isAttestable',
        control,
    });
    const { field: hasImpactCertificateField } = useController({
        name: 'hasImpactCertificate',
        control,
    });

    const { field: impactBriefField } = useController({
        name: 'impactBrief',
        control,
        rules: {
            required: hasImpactCertificateField?.value ? 'Please enter a brief description' : false,
            maxLength: hasImpactCertificateField?.value
                ? { value: 100, message: 'The maximum length is 100 characters' }
                : false,
        },
    });
    const { field: coresField } = useController({
        name: 'cores',
        control,
    });
    const { field: dominantSDGField } = useController({
        name: 'dominantSDG',
        control,
        rules: { required: 'Please choose dominant SDG' },
    });
    const { field: SDGsField } = useController({
        name: 'SDGs',
        control,
    });

    // const {
    //     fields: SDGsField,
    //     append: appendSDG,
    //     prepend,
    //     remove: removeSDG,
    //     swap,
    //     // move,
    //     // remove,
    // } = useFieldArray({
    //     name: 'SDGs',
    //     control,
    //     rules: {
    //         required: 'Please add atleast 1 SDG',
    //     },
    // });

    // const { field: modeField } = useController({
    //     name: 'mode',
    //     control,
    // });
    return {
        tokenField,
        tokens,
        totalRewardAmountField,
        selfFundingField,
        noOfWinnersField,
        passThresholdField,
        hasImpactCertificateField,
        impactBriefField,
        coresField,
        dominantSDGField,
        SDGsField,
        isAttestableField,
        distributionModeField,
        ranksField,
        withRewardTokenField,
        appendRank,
        removeRank,
        watchedRanks,
        validationTillDateField,
        daySubField,
        hoursSubField,
        minutesSubField,
    };
};
