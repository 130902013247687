import axios from 'axios';
import { BASE_URL } from '../../constants/apiPaths';
import { getAccessToken } from '../../firebase';
import { toast } from 'sonner';
const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};
// POST
export const createBounty = async (bounty) => {
    try {
        const url = `${BASE_URL}/bounty?version=2`;

        const accessToken = await getAccessToken();

        const response = await axios.post(url, bounty, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) return response.data;
    } catch (error) {
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};

export const updateBounty = async (bounty, bountyId) => {
    try {
        const url = `${BASE_URL}/bounty/${bountyId}?version=2`;

        const accessToken = await getAccessToken();

        const response = await axios.put(url, bounty, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) return response.data;
    } catch (error) {
        toast.error(error);
        return error;
        // toast.error('Sorry unable to create your organization.');
    }
};
// GET /organisation/bounty?organisationId=<id>&page=<page number>&entriesPerPage=<per page entry count></per>
export const getBounties = async (id) => {
    try {
        const url = `${BASE_URL}/organisation/bounty?organisationId=${id}&page=1&entriesPerPage=100`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const getUnvalidatedBounties = async (id, page, pageEntries) => {
    try {
        const url = `${BASE_URL}/organisation/validation/bounty?organisationId=${id}&page=${page}&entriesPerPage=${pageEntries}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

// GET /organisation/validation/bounty/<bountyId>?page=<page number>&entriesPerPage=<per page entry count></per>

export const getBounty = async (id) => {
    try {
        const url = `${BASE_URL}/bounty/details/${id}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const deleteBounty = async (id) => {
    try {
        const url = `${BASE_URL}/bounty/${id}`;

        const accessToken = await getAccessToken();
        const response = await axios.delete(url, getConfig(accessToken));

        // if (response.status === 201) toast.success('Project is successfully created !');
        return response.data;
    } catch (error) {
        toast.error(error.response.data.message);
        // toast.error('Sorry unable to create your organization.');
    }
};

export const getBountySubmissions = async (bountyId, page = 1, entriesPerPage = 1000) => {
    try {
        const url = `${BASE_URL}/organisation/validation/bounty/${bountyId}?page=${page}&entriesPerPage=${entriesPerPage}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        console.log(response);
        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

// /organisation/validation/preview/<bountyId></bountyId>
export const getValidationPreview = async (bountyId) => {
    try {
        const url = `${BASE_URL}/organisation/validation/preview/${bountyId}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
export const getBountySubmission = async (submissionId) => {
    try {
        const url = `${BASE_URL}/organisation/validation/submission/${submissionId}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
// PATCH /organisation/validation/submission/<submissionId></submissionId>

export const remarkSubmission = async (submissionId, payload) => {
    try {
        const url = `${BASE_URL}/organisation/validation/submission/${submissionId}`;

        const accessToken = await getAccessToken();
        const response = await axios.patch(url, payload, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) toast.success(' successfully validated !');
        return response;
    } catch (error) {
        toast.error(error?.response?.data?.message);
        // toast.error('Sorry unable to create your organization.');
    }
};
// PATCH:${BASE_URL}/bounty/start<bountyId>
export const startBounty = async (payload, bountyId, transactionHash) => {
    try {
        // PATCH /bounty/start?transactionHash=<hash></hash>
        const url = `${BASE_URL}/bounty/start/${bountyId}${transactionHash ? `transactionHash=${transactionHash}` : ''}
          `;

        const accessToken = await getAccessToken();

        const response = await axios.patch(url, payload, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) return response.data;
    } catch (error) {
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};

export const requestFunding = async (payload, bountyId, fundingRequiredBy) => {
    try {
        const url = `${BASE_URL}/bounty/requestFunding?bountyId=${bountyId}&fundingRequiredBy=${fundingRequiredBy}`;

        const accessToken = await getAccessToken();

        const response = await axios.patch(url, payload, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) return response.data;
    } catch (error) {
        return { success: false, status: error?.response?.data?.code };
        // toast.error('Sorry unable to create your organization.');
    }
};

export const sendRequestForVoting = async (payload) => {
    try {
        const url = `${BASE_URL}/orgRequest`;

        const accessToken = await getAccessToken();

        const response = await axios.post(url, payload, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) return response.data;
    } catch (error) {
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};

export const publishOrUnpublishBounty = async (id) => {
    try {
        const url = `${BASE_URL}/bounty/togglePublish/${id}`;

        const accessToken = await getAccessToken();
        const response = await axios.patch(url, {}, getConfig(accessToken));

        if (response.status === 201 || response.status === 200) toast.success(' Bounty status changed !');
        return response;
    } catch (error) {
        toast.error(error);
        // toast.error('Sorry unable to create your organization.');
    }
};
