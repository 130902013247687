import Heading from '../../ui/Heading';
import Input from '../../ui/Input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextWithTooltip from '../../ui/TextWithTooltip';

import { useStepFourForLandscapes } from './bountiesHooks/useStepFourForLandscapes';

function StepFourForLandscapes({ control, errors }) {
    const { walletReceiveField, fundingRequiredByField } = useStepFourForLandscapes(control);

    return (
        <div className="relative">
            <div className=" pb-0 flex flex-col justify-between min-h-full h-  rounded-md ">
                <div>
                    <div className="mb-6">
                        <p className="text-primaryInput">
                            In which wallet do you want to receive the funds?
                            <sup className="text-lg relative top-[1px]">*</sup>
                        </p>
                        <Input
                            {...walletReceiveField}
                            type="text"
                            error={errors?.walletReceive?.message}
                            // onChange={(e) => {
                            //     // if (distributionModeField.value === 'Rank based distribution') {
                            //     const count = parseInt(e.target.value, 10);
                            //     if (!isNaN(count) && count >= 0) {
                            //         // Clear existing fields if needed
                            //         const currentFields = ranksField.length;

                            //         // If the new count is greater than the current count, append fields
                            //         for (let i = currentFields; i < count; i++) {
                            //             appendRank({ value: '' });
                            //         }

                            //         // If the new count is less than the current count, remove fields
                            //         for (let i = currentFields; i > count; i--) {
                            //             removeRank(i - 1);
                            //         }
                            //         // }
                            //     }
                            //     noOfWinnersField.onChange(e.target.value);
                            // }}
                            placeholder={'Wallet Address'}
                            className={'w-[100%] mt-2'}
                        />
                    </div>
                    <div className="mb-6">
                        <div className="flex gap-3">
                            <TextWithTooltip
                                heading={
                                    <p className="text-primaryInput">
                                        When do you want the funding by ?
                                        <sup className="text-lg relative top-[1px]">*</sup>
                                    </p>
                                }
                            >
                                <p className="text-sm">
                                    This is the bounty expiration time after which it will disappear from Impact Miner
                                    and users will not be able to participate
                                </p>
                            </TextWithTooltip>
                        </div>

                        <div>
                            <DatePicker
                                showTimeSelect
                                minDate={new Date()}
                                className="border border-borderColor w-[230px] shadow-sm text-sm  bg-white placeholder:text-secondaryInput font-semibold rounded-md px-4 py-2"
                                // minTime={new Date()}
                                // maxTime={new Date(0, 0, 0, 19, 0)}
                                placeholderText="Set Date & Time"
                                selected={fundingRequiredByField.value}
                                onChange={(date) => fundingRequiredByField.onChange(date)}
                                dateFormat="MMMM d, yyyy h:mmaa"
                                timeIntervals={1}

                                // customInput={<CustomDateInput placeholder="Set Date & Time" className="bg-white" />}
                            />
                            {errors?.fundingRequiredBy && (
                                <p className="text-foundryRed">{errors?.fundingRequiredBy.message}</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StepFourForLandscapes;
