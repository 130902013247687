import { useRef, useState } from 'react';
import { InfoTooltipIcon } from '../assets/icons/UserProfileIcons';
import Heading from './Heading';
import { createPortal } from 'react-dom';
function TextWithTooltip({
    infoColor = '#969696',
    hoverOverText = '',
    hoverOverTextColor = 'text-secondaryText',
    expandedClassName = '',
    className = '',
    heading = '',
    expandedTextWidth = 'w-[600px]',
    children,
}) {
    const [show, setShow] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const hoverRef = useRef(null);
    return (
        <div ref={hoverRef} className={`  flex items-center gap-1 w-fit   `}>
            {heading ? (
                heading
            ) : (
                <Heading className={`${hoverOverTextColor} ${className}`} type={'medium'}>
                    {hoverOverText}
                </Heading>
            )}
            <div
                className="relative"
                onMouseLeave={() => {
                    setShow(false);
                }}
            >
                <InfoTooltipIcon
                    color={infoColor}
                    hoverOver={() => {
                        if (hoverRef.current) {
                            const rect = hoverRef.current.getBoundingClientRect();
                            setTooltipPosition({
                                top: rect.top + window.scrollY + 5, // Adjust for scroll position
                                left: rect.left + rect.width + 1, // Right side of the element + some margin
                            });

                            setShow(true);
                        }
                    }}
                    className="cursor-pointer"
                />
                {show &&
                    createPortal(
                        <div
                            style={{
                                position: 'absolute',
                                top: tooltipPosition.top,
                                left: tooltipPosition.left,
                                zIndex: 99999999999, // High z-index to make sure it appears on top
                            }}
                            className="absolute   flex     "
                        >
                            <div className="flex flex-col relative w-5 h-5 overflow-hidden ">
                                <div className="bg-white w-5 h-5  "></div>
                                <div className="bg-[#f6f6f6] w-10 h-10 absolute rounded-full top-0 -left-5  "></div>
                            </div>

                            <div
                                className={`bg-white ${expandedTextWidth} text-lightBlack rounded-lg shadow-tooltipShadow p-6 ${expandedClassName} `}
                            >
                                {children}
                            </div>
                        </div>,
                        document.getElementById('portal-root')
                    )}
            </div>
        </div>
    );
}

export default TextWithTooltip;
