import { useEffect, useRef, useState } from 'react';
import Overlay from '../../ui/Overlay';
import Button from '../../ui/Button';
import { createBounty, getBounty, startBounty, updateBounty } from '../../apis/bounties/bounties';
import Feedbackclose from '../../assets/icons/Feedbackclose';
import ModalBox from '../../ui/ModalBox';
import { sanitizeBountyData } from '../../helpers/sanitizeBountyDetails';
import { useLoadingState } from '../../hooks/useLoader';
import Heading from '../../ui/Heading';
import TextWithTooltip from '../../ui/TextWithTooltip';
import DatePicker from 'react-datepicker';
import Input from '../../ui/Input';
import { useStepFourInputs } from './bountiesHooks/useStepFourInputs';
import { useController, useForm } from 'react-hook-form';

import Loader from '../../ui/Loader';
import { extractTimeComponents } from '../../helpers/dataModifier';
import { dateToIsoString } from '../../helpers/dateToIsoString';
import { convertToMilliseconds } from '../../helpers/addTime';
import { toast } from 'sonner';
function StartBountyModal({ close, bountyName, bountyId, handleConfirmAction }) {
    const modalRef = useRef(null); // Reference for the modal content
    const { loading, startLoading, stopLoading } = useLoadingState();
    const {
        loading: gettingBounty,
        startLoading: startGettingBounty,
        stopLoading: stopGettingBounty,
    } = useLoadingState();
    const [bountyPayload, setBountyPayload] = useState({});
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            ...bountyPayload,
            expirationTime: bountyPayload?.estimatedCompletionTime ? bountyPayload?.estimatedCompletionTime : undefined,
            day: bountyPayload?.estimatedCompletionTime
                ? extractTimeComponents(bountyPayload?.estimatedCompletionTime)?.days
                : 0,
            hours: bountyPayload?.estimatedCompletionTime
                ? extractTimeComponents(bountyPayload?.estimatedCompletionTime)?.hours
                : 0,
            minutes: bountyPayload?.estimatedCompletionTime
                ? extractTimeComponents(bountyPayload?.estimatedCompletionTime)?.minutes
                : 0,
        },
    });
    const { expirationTimeField, dayField, hoursField, minutesField } = useStepFourInputs(control);
    const { field: validationTillDateField } = useController({
        name: 'validationTillDate',
        control,
        rules: {
            required: 'Please enter a date',

            // validate: (value) => {
            //     if (!withRewardTokenField?.value) return true;
            //     if (!expirationTimeField.value) {
            //         return 'Please select the first date before selecting the second date.';
            //     }

            //     return true;
            // },
        },
    });
    const hasError = errors?.day?.message || errors?.hours?.message || errors?.minutes?.message ? true : false;

    useEffect(() => {
        // Fetch the bounty data asynchronously
        const fetchData = async () => {
            try {
                startGettingBounty();
                const data = await getBounty(bountyId);
                setBountyPayload(data);
                // Extract time components and update form values
                const timeComponents = extractTimeComponents(data?.estimatedCompletionTime || 0);
                reset({
                    ...data,
                    expirationTime: data?.expirationTimestamp ? new Date(data?.expirationTimestamp) : undefined,
                    day: timeComponents?.days || 0,
                    hours: timeComponents?.hours || 0,
                    minutes: timeComponents?.minutes || 0,
                    validationTillDate: data?.validationTillDate ? new Date(data?.validationTillDate) : undefined,
                });
            } catch (error) {
                console.error('Error fetching bounty:', error);
            } finally {
                stopGettingBounty();
            }
        };
        fetchData();

        // Handle click outside the modal
        function handleClickOutside(e) {
            // Check if the click was outside the modal content
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                close(); // Close the modal if clicked outside
            }
        }

        // Add event listener in the capture phase to ensure proper handling
        document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            // Clean up the event listener on unmount
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [close, bountyId]);

    const onSubmit = async (data) => {
        const { expirationTime, day, hours, minutes, validationTillDate } = data;
        const expirationDate = new Date(expirationTime);
        const validationDate = new Date(validationTillDate);
        const currentDate = new Date();
        // Validation: expirationTime should be in the future
        if (expirationDate <= currentDate) {
            toast.error('Expiration time must be in the future.');
            return;
        }

        // Validation: validationTillDate should be after expirationTime
        if (validationDate <= expirationDate) {
            toast.error('Validation till date must be after the expiration time.');
            return;
        }

        const payload = {
            ...bountyPayload,
            expirationTimestamp: dateToIsoString(new Date(expirationTime)),
            estimatedCompletionTime: convertToMilliseconds(Number(day), Number(hours), Number(minutes)),
            validationTillDate: dateToIsoString(new Date(validationTillDate)),
        };

        const {
            __v,
            id,
            _id,
            createdAt,
            updatedAt,
            creatorUid,
            fundReceivingWallet,
            fundedAmount,
            fundingRequestTime,
            fundingRequiredBy,
            ...payload3
        } = payload;

        const sanitizedPayload = sanitizeBountyData(payload3);

        try {
            startLoading();

            const response = await updateBounty(sanitizedPayload, bountyPayload?._id);

            if (!response) {
                stopLoading();
                toast.error('Unable to create bounty. Please check with Atlantis customer support. ');
                return;
            }
            if (response?._id) {
                const creationPayload = {
                    organisationId: id,
                    requestType: 'BOUNTY_CREATION',
                    bountyId: response._id,
                    lastUpdateTime: response.updatedAt,
                };

                if (response?.status === 'Draft') {
                    const responseRequest = await startBounty(creationPayload, creationPayload?.bountyId);

                    if (responseRequest?.id) {
                        // setStep(7);
                        handleConfirmAction(responseRequest);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            stopLoading();
            close();
        }
    };
    console.log(expirationTimeField, validationTillDateField);
    return (
        <Overlay>
            <div
                ref={modalRef} // Attach ref to modal content
                className="absolute rounded-lg left-[50%]     bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
                onClick={(e) => e.stopPropagation()} // Stop propagation to avoid closing the modal when clicked inside
            >
                <ModalBox
                    close={close}
                    width="w-[531px]"
                    isConfirmModal={false}
                    onSubmit={onSubmit}
                    primaryBtnText={'Publish'}
                    isTypeSubmit={true}
                    loading={loading}
                    secondaryBtnText={'Cancel'}
                >
                    <div>
                        <div className="pb-4 border-b border-borderColor">
                            <h3 className="text-xl font-bold text-lightBlack"> Publish Bounty</h3>
                        </div>

                        {gettingBounty ? (
                            <div className="min-h-[400px] flex items-center justify-center ">
                                <Loader />
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit(onSubmit)} className="pt-4">
                                <div className="mb-6">
                                    <div className="flex gap-3 mb-1">
                                        <TextWithTooltip
                                            heading={
                                                <Heading type="xsmall" className="capitalize  text-primaryInput">
                                                    Once published, how long should the bounty be live?{' '}
                                                    <sup className="text-lg relative top-[1px]">*</sup>
                                                </Heading>
                                            }
                                        >
                                            <p className="text-sm">
                                                This is the bounty expiration time after which it will disappear from
                                                Impact Miner and users will not be able to participate
                                            </p>
                                        </TextWithTooltip>
                                    </div>

                                    <div>
                                        <DatePicker
                                            showTimeSelect
                                            minDate={new Date()}
                                            className="border border-borderColor w-[230px] shadow-sm text-sm  bg-white placeholder:text-secondaryInput font-semibold rounded-md px-4 py-2"
                                            // minTime={new Date()}
                                            // maxTime={new Date(0, 0, 0, 19, 0)}
                                            placeholderText="Set Date & Time"
                                            selected={expirationTimeField.value}
                                            onChange={(date) => expirationTimeField.onChange(date)}
                                            dateFormat="MMMM d, yyyy h:mmaa"
                                            timeIntervals={1}

                                            // customInput={<CustomDateInput placeholder="Set Date & Time" className="bg-white" />}
                                        />
                                        {errors.expirationTime && (
                                            <p className="text-foundryRed">{errors.expirationTime.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="my-6">
                                    <div className="flex gap-3 mb-1">
                                        <TextWithTooltip
                                            heading={
                                                <Heading type="xsmall" className="capitalize  text-primaryInput">
                                                    Estimated time to complete bounty{' '}
                                                    <sup className="text-lg relative top-[1px]">*</sup>
                                                </Heading>
                                            }
                                        >
                                            <p className="text-sm">
                                                An estimation of time will help users on Impact Miner understand how
                                                long it will take to complete the bounty
                                            </p>
                                        </TextWithTooltip>
                                    </div>
                                    <div className="flex w-[50%] mt-1 gap-1">
                                        <div>
                                            <div>
                                                <Input
                                                    {...dayField}
                                                    className="w-full text-sm font-semibold"
                                                    type="number"
                                                    placeholder={0}
                                                />
                                                <p className="">day</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div>
                                                <Input
                                                    {...hoursField}
                                                    className="w-full text-sm font-semibold"
                                                    type="number"
                                                    placeholder={0}
                                                />
                                                <p>hours</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <Input
                                                    {...minutesField}
                                                    className="w-full text-sm font-semibold"
                                                    type="number"
                                                    placeholder={0}
                                                />
                                                <p>minutes</p>
                                            </div>
                                        </div>
                                    </div>
                                    {hasError && <p className="text-foundryRed">{'Please enter the estimated time'}</p>}
                                </div>
                                <div className="my-6">
                                    <div className="flex gap-3 mb-1">
                                        <TextWithTooltip
                                            heading={
                                                <Heading type="xsmall" className="capitalize  text-primaryInput">
                                                    Set Deadline for validating all submissions{' '}
                                                    <sup className="text-lg relative top-[1px]">*</sup>
                                                </Heading>
                                            }
                                        >
                                            <p className="text-sm">
                                                Validation determines the score of each submission and in turn the
                                                winners.
                                            </p>
                                            <p className="text-sm mt-3">
                                                Setting a deadline will inform users how long it will take for the
                                                results to be announced
                                            </p>
                                        </TextWithTooltip>
                                    </div>
                                    <div>
                                        <DatePicker
                                            minDate={new Date(expirationTimeField.value)}
                                            showTimeSelect
                                            className="border border-borderColor w-[230px] shadow-sm text-sm  bg-white placeholder:text-secondaryInput font-semibold rounded-md px-4 py-2"
                                            placeholderText="Set Date & Time"
                                            selected={validationTillDateField?.value}
                                            disabled={!expirationTimeField?.value}
                                            onChange={(date) => {
                                                if (expirationTimeField?.value) validationTillDateField?.onChange(date);
                                                return;
                                            }}
                                            dateFormat="MMMM d, yyyy h:mmaa"
                                            timeIntervals={15}
                                        />
                                        {errors.validationTillDate && (
                                            <p className="text-foundryRed">{errors.validationTillDate.message}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center mt-8 gap-3">
                                    <Button
                                        className="text-base font-semibold"
                                        radius="rounded-full"
                                        // onClick={onSubmit}
                                        size="round"
                                        variant="primary"
                                        type="submit"
                                    >
                                        {loading ? <Loader /> : 'Publish'}
                                    </Button>
                                    <Button
                                        className="text-base font-medium text-lightBlack"
                                        radius="rounded-full"
                                        onClick={close}
                                        size="round"
                                        variant="tertiary"
                                        // type="button"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </form>
                        )}
                    </div>
                </ModalBox>
            </div>
        </Overlay>
    );
}

export default StartBountyModal;
