import { useState } from 'react';
import { BountiesProvider } from '../contexts/BountiesContext';
import BountiesMain from '../features/bounties/BountiesMain';

import BountiesSideBar from '../features/bounties/BountiesSideBar';
import RequestCreated from '../features/bounties/RequestCreated';

function AddBounty() {
    const [step, setStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([]);
    const [isBackerRequired, setIsBackerRequired] = useState(true);

    const backerHandler = (val) => {
        setIsBackerRequired(val);
    };

    return (
        <div
            className={`grid ${
                step === 7 ? 'grid-cols-1  ' : 'grid-cols-createBounty'
            }  overflow-hidden   pt-8  px-9 pr-0   `}
        >
            <BountiesProvider>
                {step === 7 ? (
                    <RequestCreated />
                ) : (
                    <>
                        <BountiesSideBar
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            step={step}
                            setStep={setStep}
                            isBackerRequired={isBackerRequired}
                        />
                        <BountiesMain
                            completedSteps={completedSteps}
                            setCompletedSteps={setCompletedSteps}
                            step={step}
                            setStep={setStep}
                            backerHandler={backerHandler}
                        />
                    </>
                )}
            </BountiesProvider>
        </div>
    );
}

export default AddBounty;
