import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Table from '../../ui/Table';
import Menus from '../../ui/Menus';
import { useEffect, useState } from 'react';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import NoDataAvailable from '../../ui/NoDataAvailable';
import { getEvents } from '../../apis/events/getEvents';
import PublishMenuIcon from '../../assets/icons/PublishMenuIcon';
import DeleteEventModal from './DeleteEventModal';
import { toggleEvent } from '../../apis/events/togglePublish';

import Tag from '../../ui/Tag';
import { EVENT_STATUS_TAG_COLOR } from '../../constants/datas';
import DeleteIcon from '../../assets/icons/DeleteIcon';

function EventsList() {
    const navigate = useNavigate();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const { id } = useParams();
    const location = useLocation();
    let relativeAddress = location.pathname.split('/');
    relativeAddress.pop();
    relativeAddress = relativeAddress.join('/');
    const [events, setEvents] = useState([]);

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();
                    const data = await getEvents(id);

                    setEvents(data);
                    stopLoading();
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [id]
    );

    const handleOpenModal = (action, event) => {
        setModalAction(action); // Set the action type ('duplicate' or 'delete')
        setSelectedEvent(event); // Set the selected bounty
        setModalOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setModalOpen(false); // Close the modal
        setModalAction(''); // Clear action type
        setSelectedEvent(null); // Clear selected bounty
    };

    const handleConfirmAction = (id, data) => {
        if (modalAction === 'delete') {
            const afterDelete = events.filter((event) => event._id !== id);
            setEvents(afterDelete);
        }
        handleCloseModal();
    };

    const handleTogglePublish = async (id, oppStatus) => {
        try {
            const afterToggling = events.map((event) => {
                if (event._id === id) return { ...event, isLive: oppStatus };
                return event;
            });
            setEvents(afterToggling);
            const res = await toggleEvent(id);
        } catch (error) {}
    };
    console.log(events, 'greenpill');

    return (
        <>
            <Menus>
                <Table className="items-center" cols="grid-cols-tableEvents  gap-14">
                    <Table.Description
                        descriptionClass=" !mb-0"
                        description="Events in the Organisation "
                        primaryBtn="+ Add Event"
                        clickOnPrimary={() => navigate(`/${id}/createEvent`)}
                    />

                    <Table.Header>
                        {/* <p className="text-sm   font-semibold text-secondaryText ">Project ID</p> */}
                        <p className="text-sm  font-semibold text-secondaryText ">Event Title</p>
                        <p className="text-sm   font-semibold text-secondaryText ">Bounties Live</p>
                        <p className="text-sm   font-semibold text-secondaryText ">Status</p>
                        <p className="text-sm  font-semibold text-secondaryText "></p>
                    </Table.Header>
                    {!loading ? (
                        events.length > 0 ? (
                            events.map((event) => {
                                const isOrgIdMatch = event?.organisationId === id;

                                return (
                                    <Table.Row key={event._id} rowClassName="relative">
                                        <p
                                            onClick={() => navigate(event._id)}
                                            className="text-primaryColor cursor-pointer truncate w-full  justify-self-start font-medium"
                                        >
                                            {event.name}
                                        </p>
                                        <p className="text-primaryInput">
                                            {event.liveBountyCount}/{event.totalBountyCount}
                                        </p>
                                        <Tag
                                            rounded="rounded-lg "
                                            className="px-2 py-1 leading-3 self-center   "
                                            textClassName="text-xs font-semibold"
                                            bg={EVENT_STATUS_TAG_COLOR[event.isLive ? 'Live' : 'Unpublished']?.bg}
                                            textColor={
                                                EVENT_STATUS_TAG_COLOR[event.isLive ? 'Live' : 'Unpublished']?.text
                                            }
                                            text={event.isLive ? 'Live' : 'Unpublished'}
                                        />
                                        {isOrgIdMatch && <Menus.Toggle id={event._id} />}

                                        <Menus.List id={event._id}>
                                            {event.isLive ? (
                                                <Menus.Button
                                                    onClick={(fn) => {
                                                        handleTogglePublish(event._id, false);
                                                        fn();
                                                    }}
                                                    icon={<PublishMenuIcon />}
                                                >
                                                    Unpublish
                                                </Menus.Button>
                                            ) : (
                                                <Menus.Button
                                                    onClick={(fn) => {
                                                        handleTogglePublish(event._id, true);
                                                        fn();
                                                    }}
                                                    icon={<PublishMenuIcon />}
                                                >
                                                    Publish
                                                </Menus.Button>
                                            )}

                                            <Menus.Button
                                                icon={<DeleteIcon />}
                                                shouldCloseMenu={true}
                                                onClick={(fn) => {
                                                    handleOpenModal('delete', event);
                                                    fn();
                                                }}
                                            >
                                                Delete
                                            </Menus.Button>
                                        </Menus.List>
                                    </Table.Row>
                                );
                            })
                        ) : (
                            <NoDataAvailable />
                        )
                    ) : (
                        <div className="min-h-[20vh] flex justify-center items-center">
                            <Loader />
                        </div>
                    )}
                </Table>
            </Menus>
            {isModalOpen && (
                <div>
                    {modalAction === 'delete' && (
                        <DeleteEventModal
                            onDeletion={handleConfirmAction}
                            eventName={selectedEvent.name}
                            eventId={selectedEvent._id}
                            close={handleCloseModal}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default EventsList;
