import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

const DraggableList = ({
    microtaskFields,
    swap, // Use swap from react-hook-form's useFieldArray
    renderMicrotask, // This is the render function
    setValue,
    update,
    getValues,
}) => {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 5 },
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (!over || active.id === over.id) return;

        const oldIndex = microtaskFields.findIndex((item) => item.id === active.id);
        const newIndex = microtaskFields.findIndex((item) => item.id === over.id);

        console.log(`Swapping indexes: ${oldIndex} <--> ${newIndex}`);

        // ✅ Get the current form state from React Hook Form
        const currentValues = getValues('microtasks');

        // ✅ Create a deep copy of the form state to avoid reference issues
        const updatedValues = [...currentValues];

        // ✅ Swap the values of the two fields
        [updatedValues[oldIndex], updatedValues[newIndex]] = [updatedValues[newIndex], updatedValues[oldIndex]];

        // ✅ Apply the updated state to React Hook Form
        setValue('microtasks', updatedValues, { shouldDirty: true });

        // ✅ Swap field positions in useFieldArray
        swap(oldIndex, newIndex);

        // ✅ Ensure the form state is correctly updated
        update(oldIndex, updatedValues[oldIndex]);
        update(newIndex, updatedValues[newIndex]);
    };

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={microtaskFields.map((item) => item.id)} strategy={verticalListSortingStrategy}>
                {microtaskFields.map(renderMicrotask)}
            </SortableContext>
        </DndContext>
    );
};

export default DraggableList;
