import { useContext, useState } from 'react';
import Button from '../../ui/Button';
import StepOne from './StepOne';
import { BountiesContext } from '../../contexts/BountiesContext';
import StepTwo from './StepTwo';
import StepFour from './StepFour';
import StepThree from './StepThree';
import StepFive from './StepFive';
import StepSix from './StepSix';
import { dateToIsoString } from '../../helpers/dateToIsoString';
import { convertToMilliseconds } from '../../helpers/addTime';
import {
    createBounty,
    deleteBounty,
    publishOrUnpublishBounty,
    requestFunding,
    startBounty,
    updateBounty,
} from '../../apis/bounties/bounties';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useForm, useWatch } from 'react-hook-form';
import BountiesPreview from './BountiesPreview';
import { getMicroTaskFieldsAndValues } from '../../helpers/getMicroTaskFieldsAndValues';
import { useLoadingState } from '../../hooks/useLoader';
import StepCompleted from '../../assets/icons/StepCompleted';
import { extractTimeComponents, isEvenlyDistributed } from '../../helpers/dataModifier';
import { extractStringAfterColon } from '../../helpers/extractStringAfterColon';
import { isDateAndTimeValidForStartingBounty } from '../../helpers/isDateAndTimeValidForStartingBounty';
import { toggleOnboardBounty } from '../../apis/events/getEvents';

function BountiesMain({ step, setStep, completedSteps, setCompletedSteps, backerHandler }) {
    const [descriptionLinkPayload, setDescriptionLinkPayload] = useState([]);
    const { isEdit, bountyEditDetails, loadingEditDetails } = useContext(BountiesContext);
    const navigate = useNavigate();
    const [isValidate, setIsValidate] = useState(true);
    const location = useLocation();
    const [instructionLinkPayload, setInstructionLinkPayload] = useState([]);
    const [activeMicrotask, setActiveMicrotask] = useState(0);
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [impactPointsData, setImpactPointsData] = useState(0);
    const [radiusPreview, setRadiusPreview] = useState(50);
    const searchParams = new URLSearchParams(location.search);

    const eventNameFromUrl = decodeURIComponent(searchParams.get('eventName') || '');
    const isGreenpillEvent = decodeURIComponent(searchParams.get('isGreenpillEvent') || '') === 'true';
    const eventId = decodeURIComponent(searchParams.get('eventId') || '');

    const {
        register,
        handleSubmit,
        control,
        getValues,
        trigger,
        formState: { errors },
        setError,
        reset,
        unregister,
        setValue,
        clearErrors,
    } = useForm(
        isEdit
            ? {
                  mode: 'onChange',
                  defaultValues: {
                      ...bountyEditDetails,
                      type: extractStringAfterColon(bountyEditDetails?.type) || bountyEditDetails?.type,
                      bountyDescription: bountyEditDetails?.description,
                      dominantCore: bountyEditDetails?.cores[0],
                      criteria: bountyEditDetails?.criteria?.map((c) => ({ criteriaName: c })),
                      microtasks: bountyEditDetails?.microtasks?.map((mt) => {
                          return {
                              microtaskType: mt?.actions?.[0]?.actionType, // Type from the first action
                              skippable: mt?.skippable,
                          };
                      }),

                      expirationTime: new Date(bountyEditDetails?.expirationTimestamp),
                      ...(bountyEditDetails?.hasOwnProperty('backer') && {
                          hasBacker: true,
                          backerName: bountyEditDetails?.backer?.name || '',
                          backerMessage: bountyEditDetails?.backer?.message || '',
                          backerLogo: bountyEditDetails?.backer?.image || '',
                      }),

                      ...(bountyEditDetails?.hasOwnProperty('rewardToken') && {
                          totalRewardAmount: bountyEditDetails?.rewardToken?.totalRewardAmount,
                          noOfWinners: bountyEditDetails?.rewardToken?.distribution?.length,
                          ...(!isEvenlyDistributed(bountyEditDetails?.rewardToken?.distribution) && {
                              ranks: bountyEditDetails?.rewardToken?.distribution.map((a) => ({ amount: a })),
                          }),
                          withRewardToken: true,
                          validationTillDate: new Date(bountyEditDetails?.validationTillDate),
                          validationType: 'ALL_TOGETHER',

                          //   maxValidationDuration,
                      }),
                      ...(!bountyEditDetails?.hasOwnProperty('rewardToken') && {
                          daySub: extractTimeComponents(bountyEditDetails?.maxValidationDuration)?.days,
                          hoursSub: extractTimeComponents(bountyEditDetails?.maxValidationDuration)?.hours,
                          minutesSub: extractTimeComponents(bountyEditDetails?.maxValidationDuration)?.minutes,
                          validationType: 'AFTER_EACH_SUBMISSION',
                      }),
                      token: bountyEditDetails?.tokenInfo,

                      passThreshold: bountyEditDetails?.passThreshold * 100,
                      dominantSDG: bountyEditDetails?.SDGs[0],
                      distributionMode: bountyEditDetails?.rewardToken
                          ? isEvenlyDistributed(bountyEditDetails?.rewardToken?.distribution)
                              ? 'Distribute evenly between all winners'
                              : 'Rank based distribution'
                          : 'Distribute evenly between all winners',

                      day: extractTimeComponents(bountyEditDetails?.estimatedCompletionTime)?.days,
                      hours: extractTimeComponents(bountyEditDetails?.estimatedCompletionTime)?.hours,
                      minutes: extractTimeComponents(bountyEditDetails?.estimatedCompletionTime)?.minutes,
                      selfFunding: 'Yes, we will fund the bounty',
                  },
              }
            : {
                  mode: 'onChange',
                  defaultValues: {
                      maxValueString: 'abc',
                      mode: 'digital',
                      criteria: [{ criteriaName: '' }],
                      microtasks: [
                          {
                              microtaskType: '',
                          },
                      ],
                      hasImpactCertificate: false,
                      cores: [],
                      SDGs: [],
                      isAttestable: true,
                      hasBacker: true,
                      hasInitiator: false,
                      location: { coordinates: [] },
                      bountyRadius: 50,
                      distributionMode: 'Distribute evenly between all winners',
                      withRewardToken: true,
                      selfFunding: 'Yes, we will fund the bounty',
                  },
              }
    );
    const microtasksValues = useWatch({
        control,
        name: 'microtasks',
    });
    const [icImage, setIcImage] = useState({});
    const {
        loading: icFetching,
        startLoading: startGettingIcImage,
        stopLoading: stopGettingIcImage,
    } = useLoadingState();
    const criteriaValues = useWatch({
        control,
        name: 'criteria',
    });

    const { id } = useParams();

    const validationHandler = (value) => {
        setIsValidate(value);
    };

    let dataForMicroTask;
    const getDataForMicroTask = (data) => {
        dataForMicroTask = data;
        return dataForMicroTask;
    };

    const handleStepChange = async (nextStep, i, typeMicrotask) => {
        console.log(nextStep, step);

        if (nextStep < step) {
            setCompletedSteps((prev) => prev.filter((s) => s < nextStep));
            setStep(nextStep);
            return;
        }
        const isValid = await trigger(); // Manually trigger validation for all fields
        console.log(errors);
        if (isValid) {
            setStep(nextStep);
            setCompletedSteps((prev) => {
                if (!prev.includes(nextStep - 1)) {
                    return [...prev, nextStep - 1]; // Add the previous step to completedSteps if not already added
                }
                return prev;
            });
        } else {
            console.log('Validation failed');
        }
    };

    const handleDescriptionLinkPayload = (value) => {
        setDescriptionLinkPayload((prev) => [...value]);
    };
    const handleInstructionLinkPayload = (value) => {
        setInstructionLinkPayload(value);
        // You can now store this payload or pass it along for submission
    };
    const handleManualValueSetting = async (nextStep, field, value) => {
        const isValid = value?.length ? true : false;
        if (!isValid) {
            setError(field, {
                type: 'manual',
                message: 'Please select microtask type',
            });
        } else {
            clearErrors(field); // Clear error if validation passes

            const isValid = await trigger(); // Manually trigger validation for all fields
            if (isValid) {
                if (nextStep) setStep(nextStep);
            } else {
                console.log('Validation failed');
            }
        }
    };

    // const setFieldValue = (field, value) => {
    //     setValue(field, value, { shouldValidate: true }); // Set new value and trigger validation
    // };

    const onDelete = async () => {
        try {
            const response = await deleteBounty();
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async (data, isCancel) => {
        let distribution = [];
        console.log(data, isCancel, 'greenpill changes data');
        const {
            mode,
            criteria,
            microtasks,
            hasImpactCertificate,
            cores,
            SDGs,
            isAttestable,
            hasBacker,
            withRewardToken,
            // hasInitiator,
            location,
            bountyRadius,
            distributionMode,
            selfFunding,
            title,
            city,
            bountyDescription,
            projectId,
            type,
            // dominantCore,
            maxAcceptancesPerUser,
            backerName,
            backerLogo,
            backerMessage,
            walletReceive,
            fundingRequiredBy,
            // initiatorName,
            // initiatorLogo,
            // initiatorMessage,
            token,
            totalRewardAmount,
            noOfWinners,
            passThreshold,
            impactBrief,
            // dominantSDG,
            expirationTime,
            validationTillDate,
            ranks,
            day = 0,
            hours = 0,
            minutes = 0,
            daySub,
            hoursSub,
            minutesSub,
        } = data;
        if (distributionMode === 'Distribute evenly between all winners') {
            const rewardPerPerson = Number((totalRewardAmount / noOfWinners).toFixed(token?.decimals));
            distribution = Array.from({ length: noOfWinners }, (_) => rewardPerPerson);
        } else {
            distribution = ranks
                .filter((rank) => rank.amount)
                .map((c) => Number(Number(c.amount).toFixed(token?.decimals)));
        }

        const mappedMicroTasks = microtasks.map((micro, indexMicroTask) => {
            const { microtaskType, skippable, ...filteredObj } = micro;

            if (microtaskType === 'YOUTUBE_VIDEO')
                return {
                    actions: filteredObj.youtubeVideos_Link?.map((obj, i) => {
                        if (i !== 0) filteredObj.youtubeVideos_Title = '';
                        filteredObj.youtubeVideos_Description = obj.description;
                        filteredObj.youtubeVideos_Link = obj.link;
                        return {
                            actionType: microtaskType,
                            action: getMicroTaskFieldsAndValues(filteredObj),
                        };
                    }),
                    skippable: microtasks.length === indexMicroTask + 1 ? false : skippable,
                };
            return {
                actions: [
                    {
                        actionType: microtaskType,
                        action: getMicroTaskFieldsAndValues(filteredObj, microtaskType, instructionLinkPayload),
                    },
                ],
                skippable: microtasks.length === indexMicroTask + 1 ? false : skippable,
            };
        });

        const payload = {
            title,
            description: bountyDescription,

            ...(projectId && {
                projectId,
            }),

            type: eventNameFromUrl ? `Event-${eventNameFromUrl}:${type}` : type,
            cores: [...new Set(cores)],
            mode,
            ...(mode === 'physical' && {
                city,
                location,
                bountyRadius,
            }),
            ...(descriptionLinkPayload.length > 0 && {
                descriptionHyperlinks: descriptionLinkPayload,
            }),
            ...(withRewardToken && {
                rewardToken: {
                    tokenInfoId: token?._id,
                    totalRewardAmount: Number(totalRewardAmount),
                    distribution,
                },
                ...(selfFunding === 'Yes, we will fund the bounty' && {
                    validationTillDate: dateToIsoString(new Date(validationTillDate)),
                }),
            }),
            criteria: criteria
                .filter((criteria) => {
                    return criteria.criteriaName;
                })
                .map((criteria) => criteria.criteriaName),
            maxAcceptancesPerUser: Number(maxAcceptancesPerUser),
            microtasks: mappedMicroTasks,

            ...(!withRewardToken && {
                maxValidationDuration: convertToMilliseconds(Number(daySub), Number(hoursSub), Number(minutesSub)),
            }),

            ...(hasBacker &&
                isValidate && {
                    backer: {
                        name: backerName,
                        ...(backerMessage && {
                            message: backerMessage,
                        }),
                        // message: backerMessage ? backerMessage : '',
                        image: backerLogo,
                    },
                }),

            ...(selfFunding === 'Yes, we will fund the bounty' && {
                expirationTimestamp: dateToIsoString(new Date(expirationTime)),
                estimatedCompletionTime: convertToMilliseconds(Number(day), Number(hours), Number(minutes)),
            }),

            // ...(hasInitiator && {
            //     name: initiatorName,
            //     message: initiatorMessage,
            //     image: initiatorLogo,
            // }),
            hasImpactCertificate: hasImpactCertificate,
            ...(hasImpactCertificate && {
                impactBrief,
            }),

            SDGs,
            organisationId: id,
            passThreshold: Number(passThreshold) / 100,
            isAttestable,
            validationType: withRewardToken ? 'ALL_TOGETHER' : 'AFTER_EACH_SUBMISSION',
        };
        console.log(payload, 'change the structure');
        if (isCancel) {
            console.log('canceliing');
            navigate(-1);

            return;
            // async () => {
            //     console.log('clicked yes');

            //     const { __v, id, _id, createdAt, updatedAt, creatorUid, ...payload } = bountyPayload;
            //     const sanitizedPayload = sanitizeBountyData(payload);

            //     try {
            //         startLoading();
            //         const response = await createBounty(sanitizedPayload);

            //         if (response?._id) toast.success('Bounty successfully saved. ');
            //         // handleConfirmAction(response);
            //     } catch (error) {
            //         console.log(error);
            //     } finally {
            //         close(); // Close modal after duplication
            //         stopLoading();
            //         navigate(-1);
            //     }
            // };
        }
        if (isDateAndTimeValidForStartingBounty(payload?.expirationTimestamp, payload?.validationTillDate)) return;
        console.log(walletReceive, dateToIsoString(new Date(fundingRequiredBy)));
        startLoading();

        try {
            if (isEdit) {
                console.log(payload, 'payload');
                const response = await updateBounty(payload, bountyEditDetails?._id);
                console.log(response, 'response edit');
                if (!response || response?.response?.status === 401) {
                    stopLoading();
                    console?.log('is it running');
                    toast.error(
                        response?.response?.data?.message ||
                            'Unable to update bounty. Please check with Atlantis customer support. '
                    );
                    return;
                }
                if (response?._id) {
                    const creationPayload = {
                        organisationId: id,
                        requestType: 'BOUNTY_CREATION',
                        bountyId: response._id,
                        lastUpdateTime: response.updatedAt,
                    };

                    if (response?.status === 'Draft') {
                        const responseRequest = await startBounty(creationPayload, creationPayload?.bountyId);

                        if (responseRequest?.id) {
                            stopLoading();
                            setStep(7);
                        }
                    } else {
                        const res = await publishOrUnpublishBounty(creationPayload?.bountyId);

                        setStep(7);
                    }
                }
            }
            if (!isEdit) {
                console.log(payload, 'payload');
                const response = await createBounty(payload);

                if (!response) {
                    stopLoading();
                    toast.error('Unable to create bounty. Please check with Atlantis customer support. ');
                    return;
                }

                if (response?._id) {
                    const creationPayload = {
                        organisationId: id,
                        requestType: 'BOUNTY_CREATION',
                        bountyId: response._id,
                        lastUpdateTime: response.updatedAt,
                    };
                    if (eventId) {
                        const response = await toggleOnboardBounty(creationPayload?.bountyId, eventId, false);
                        console.log(response);
                    }

                    if (selfFunding === 'Yes, we will fund the bounty') {
                        const responseRequest = await startBounty(creationPayload, creationPayload?.bountyId);

                        if (responseRequest?.id) {
                            stopLoading();
                            setStep(7);
                        }
                    }
                    if (selfFunding === 'No, we want funding via Impact Landscapes') {
                        const { success } = await requestFunding(
                            {},
                            creationPayload?.bountyId,
                            walletReceive,
                            new Date(fundingRequiredBy)?.toISOString()
                        );
                        console.log('request funding done');
                        if (success) {
                            stopLoading();
                            setStep(7);
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            stopLoading();
        }
    };

    return (
        <>
            <main className="overflow-y-auto px-12 py-[30px] pt-0 overflow-x-hidden main h-screen">
                <div className="flex flex-col  h-full">
                    <div className="flex self-center gap-9">
                        {Array.from({ length: 5 }, (_, i) => i + 1).map((number, index) => {
                            const isIncluded = completedSteps.includes(number);
                            return (
                                <Button
                                    radius="rounded-full" // Ensures the button stays round
                                    className={`${
                                        isIncluded ? '!bg-[#E5F3FF] !border-[#CCE7FF] ' : 'bg-white  '
                                    } w-10 h-10 aspect-square flex !cursor-default items-center justify-center ${
                                        step === index + 1
                                            ? 'border border-primaryColor  text-primaryColor'
                                            : ' border-borderColor '
                                    }`}
                                    variant="tertiary"
                                    size="circle"
                                    key={number + index}
                                >
                                    {isIncluded ? <StepCompleted /> : number}
                                </Button>
                            );
                        })}
                    </div>
                    <div className="border-l border-r border-borderColor flex-grow mt-7 rounded-md">
                        <form
                            onSubmit={handleSubmit((data) => onSubmit(data, false))}
                            className="h-full overflow-y-auto main"
                        >
                            {step === 1 && (
                                <StepOne
                                    isFromEvent={eventNameFromUrl ? true : false}
                                    isDelete={bountyEditDetails?.status === 'Draft'}
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    setStep={handleStepChange}
                                    getValues={getValues}
                                    clearErrors={clearErrors}
                                    setValue={setValue}
                                    onClickingYes={handleSubmit((data) => onSubmit(data, true))}
                                    bountyId={bountyEditDetails?._id}
                                    descriptionLinkPayload={descriptionLinkPayload}
                                    handleDescriptionLinkPayload={handleDescriptionLinkPayload}
                                    setRadiusPreview={setRadiusPreview}
                                    projectEdit={{
                                        projectId: bountyEditDetails?.projectId,
                                        projectTitle: bountyEditDetails?.project?.title,
                                    }}
                                />
                            )}
                            {step === 2 && (
                                <StepTwo
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    setStep={handleStepChange}
                                />
                            )}
                            {step === 3 && (
                                <StepThree
                                    register={register}
                                    setValue={setValue}
                                    control={control}
                                    errors={errors}
                                    setStep={handleStepChange}
                                    getValues={getValues}
                                    getDataForMicroTask={getDataForMicroTask}
                                    activeMicrotask={activeMicrotask}
                                    microtasksValues={microtasksValues}
                                    setActiveMicrotask={setActiveMicrotask}
                                    clearErrors={clearErrors}
                                    trigger={trigger}
                                    handleManualValueSetting={handleManualValueSetting}
                                    handleInstructionLinkPayload={handleInstructionLinkPayload}
                                    instructionLinkPayload={instructionLinkPayload}
                                    microtaskEditDetails={{
                                        microtasks: bountyEditDetails?.microtasks?.map((mt) => {
                                            const actionsData = mt?.actions?.reduce((acc, action) => {
                                                if (action?.actionType === 'YOUTUBE_VIDEO') {
                                                    // Ensure youtubeVideoLink is an array
                                                    if (!acc.youtubeVideoLink) acc.youtubeVideoLink = [];

                                                    const title =
                                                        action?.action?.find((p) => p.name === 'Title')?.value || '';

                                                    action?.action?.forEach((prop) => {
                                                        if (prop?.name === 'Link') {
                                                            const description =
                                                                action?.action?.find((p) => p.name === 'Description')
                                                                    ?.value || '';
                                                            acc.youtubeVideoLink.push({
                                                                link: prop?.value,
                                                                description: description,
                                                                title: title, // Add Title for each link object
                                                            });
                                                        }
                                                    });
                                                } else {
                                                    // Process other action types dynamically
                                                    action?.action?.forEach((prop) => {
                                                        const key =
                                                            action?.actionType
                                                                ?.toLowerCase()
                                                                .split('_')
                                                                .map((word, index) =>
                                                                    index === 0
                                                                        ? word
                                                                        : word.charAt(0).toUpperCase() + word.slice(1)
                                                                )
                                                                .join('') +
                                                            '_' +
                                                            prop?.name;

                                                        acc[key] = prop?.value;
                                                    });
                                                }
                                                return acc;
                                            }, {});

                                            return {
                                                microtaskType: mt?.actions?.[0]?.actionType, // Type from the first action
                                                skippable: mt?.skippable,
                                                ...actionsData,
                                            };
                                        }),
                                    }}
                                />
                            )}
                            {/* {step === 4 && (
                                <StepFour
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    setStep={handleStepChange}
                                    getValues={getValues}
                                />
                            )} */}

                            {step === 5 && (
                                <StepFive
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    setStep={handleStepChange}
                                    getValues={getValues}
                                    setError={setError}
                                    clearErrors={clearErrors}
                                    loading={loading}
                                />
                            )}
                            {step === 4 && (
                                <StepSix
                                    loading={loading}
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    setStep={handleStepChange}
                                    getValues={getValues}
                                    setError={setError}
                                    clearErrors={clearErrors}
                                    onSubmit={onSubmit}
                                    reset={reset}
                                    setIcImage={setIcImage}
                                    icImage={icImage}
                                    impactPointsData={impactPointsData}
                                    setImpactPointsData={setImpactPointsData}
                                    startGettingIcImage={startGettingIcImage}
                                    stopGettingIcImage={stopGettingIcImage}
                                    eventNameFromUrl={eventNameFromUrl}
                                    backerHandler={backerHandler}
                                    isGreenpillEvent={isGreenpillEvent}
                                    isValidate={isValidate}
                                    validationHandler={validationHandler}
                                />
                            )}
                        </form>
                    </div>
                </div>
            </main>

            <BountiesPreview
                control={control}
                unregister={unregister}
                step={step}
                allMicrotasks={microtasksValues}
                activeMicrotask={activeMicrotask}
                criterias={criteriaValues}
                descriptionLinkPayload={descriptionLinkPayload}
                instructionLinkPayload={instructionLinkPayload}
                icImage={icImage}
                impactPointsData={impactPointsData}
                icFetching={icFetching}
                isValidate={isValidate}
                radiusPreview={radiusPreview}
            />
        </>
    );
}

export default BountiesMain;

// return (
//     <>
//         <main className=" overflow-y-auto  px-12 py-[30px] pt-0  overflow-x-hidden main">
//             <div className="flex flex-col items-center">
//                 <div className="flex gap-9    ">
//                     {Array.from({ length: 6 }, (_, i) => i + 1).map((number, index) => {
//                         const isIncluded = completedSteps.includes(number);
//                         return (
//                             <Button
//                                 radius="rounded-full" // Ensures the button stays round
//                                 className={`${
//                                     isIncluded ? '!bg-[#E5F3FF] !border-[#CCE7FF] ' : 'bg-white  '
//                                 } w-10 h-10 aspect-square flex !cursor-default items-center justify-center ${
//                                     step === index + 1
//                                         ? 'border border-primaryColor  text-primaryColor'
//                                         : ' border-borderColor '
//                                 }`}
//                                 variant="tertiary"
//                                 size="circle"
//                                 key={number + index}
//                             >
//                                 {isIncluded ? <StepCompleted /> : number}
//                             </Button>
//                         );
//                     })}
//                 </div>
//             </div>
//             <div className="border-l border-r border-borderColor h-[598px] mt-7 rounded-md">
//                 <form onSubmit={handleSubmit(onSubmit)} className=" h-[550px]  overflow-y-auto main  ">

//                 </form>
//             </div>
//         </main>

//         <BountiesPreview
//             control={control}
//             unregister={unregister}
//             step={step}
//             allMicrotasks={microtasksValues}
//             activeMicrotask={activeMicrotask}
//             criterias={criteriaValues}
//             descriptionLinkPayload={descriptionLinkPayload}
//             instructionLinkPayload={instructionLinkPayload}
//             icImage={icImage}
//             impactPointsData={impactPointsData}
//             icFetching={icFetching}
//             radiusPreview={radiusPreview}
//         />
//     </>
// );

// microtasks: bountyEditDetails?.microtasks?.map((mt, i) => {
//     console.log(mt, 'inside mt');

//     return {
//         microtaskType: mt?.actions[0]?.actionType,
//         skippable: mt?.skippable,
//         ...mt?.actions[0]?.action.map((props) => ({
//             [mt?.actions[0]?.actionType
//                 ?.toLowerCase()
//                 .split('_')
//                 .map((word, index) =>
//                     index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
//                 )
//                 .join('') + props?.name]: props?.value,
//         })),
//     };
// }),

// microtasks: bountyEditDetails?.microtasks?.map((mt) => {
//     const dynamicProps = mt?.actions[0]?.action.reduce((acc, props) => {
//         const key =
//             mt?.actions[0]?.actionType
//                 ?.toLowerCase()
//                 .split('_')
//                 .map((word, index) =>
//                     index === 0
//                         ? word
//                         : word.charAt(0).toUpperCase() + word.slice(1)
//                 )
//                 .join('') +
//             '_' +
//             props?.name;

//         acc[key] = props?.value;
//         return acc;
//     }, {});

//     return {
//         ...dynamicProps,
//         microtaskType: mt?.actions[0]?.actionType,
//         skippable: true,
//     };
// }),
