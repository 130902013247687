import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getBounty, publishOrUnpublishBounty } from '../../apis/bounties/bounties';
import WhiteCard from '../../ui/WhiteCard';
import Heading from '../../ui/Heading';
import { useLoadingState } from '../../hooks/useLoader';
import Loader from '../../ui/Loader';
import Tag from '../../ui/Tag';
import { BOUNTY_STATUS_TAG_COLOR, SDG_IMAGES } from '../../constants/datas';
import Toggle from '../../ui/Toggle';
import { EarthIcon, FistIcon, SunIcon, WaterIcon } from '../../assets/icons/ProgressBarIcons';
import IndividualBountySubDetails from './IndividualBountySubDetails';
import Button from '../../ui/Button';
import { toast } from 'sonner';

const coreIcons = {
    Earth: <EarthIcon key="earth-icon" color="" />,
    Energy: <SunIcon key="sun-icon" color="" />,
    Social: <FistIcon key="fist-icon" color="" />,
    Water: <WaterIcon key="wayer-icon" color="" />,
};

function IndividualBounty() {
    const [bounty, setBounty] = useState({});
    const { loading, startLoading, stopLoading } = useLoadingState();
    const [isPublished, setIsPublished] = useState('');
    const { id } = useParams();
    const { bountyId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const eventNameFromUrl = decodeURIComponent(searchParams.get('eventName') || '');

    useEffect(
        function () {
            const fetchData = async () => {
                try {
                    startLoading();
                    const data = await getBounty(bountyId);

                    setBounty(data);

                    setIsPublished(() => data?.status);
                    stopLoading();
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        },
        [bountyId]
    );
    console.log(bounty, 'this is the bounty');

    return (
        <>
            {loading ? (
                <div className="min-h-[20vh] flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <>
                    {Object.keys(bounty).length > 0 && (
                        <>
                            <WhiteCard className="p-6 grid grid-cols-4">
                                <div className="col-span-4">
                                    <div className="flex  items-start justify-between">
                                        <div className="mb-6">
                                            <p className="font-semibold text-secondaryInput">Bounty Title</p>
                                            <Heading className={'text-primaryInput font-medium'} type={'large'}>
                                                {bounty.title}
                                            </Heading>
                                        </div>
                                        {(isPublished === 'Draft' || isPublished === 'Live and Unpublished') && (
                                            <Button
                                                onClick={() =>
                                                    navigate(
                                                        `/${id}/createBounty?bountyId=${bounty._id}${
                                                            eventNameFromUrl ? `&eventName=${eventNameFromUrl}` : ''
                                                        }`
                                                    )
                                                }
                                                className=" w-44 text-end"
                                                size="none"
                                                variant="transparent"
                                            >
                                                Edit Bounty {'->'}
                                            </Button>
                                        )}
                                        {isPublished === 'Live and Published' && (
                                            <Button
                                                onClick={() => toast.error('Please unpublish the bounty first')}
                                                className=" w-44 text-end"
                                                size="none"
                                                variant="transparent"
                                            >
                                                Edit Bounty {'->'}
                                            </Button>
                                        )}
                                    </div>

                                    <div className="flex items-center mb-6 gap-9">
                                        <div className="">
                                            <p className="font-semibold text-secondaryInput">Project</p>
                                            <p className="font-semibold capitalize text-primaryInput ">
                                                {bounty?.project?.title || '-'}
                                            </p>
                                        </div>
                                        <div className="">
                                            <p className="font-semibold text-secondaryInput">Bounty ID</p>
                                            <p className="font-semibold uppercase text-primaryInput ">{bounty._id}</p>
                                        </div>
                                        <div className="">
                                            <p className="font-semibold text-secondaryInput">Status</p>
                                            <Tag
                                                rounded="rounded-lg "
                                                className="px-2 py-1 leading-3   "
                                                textClassName="text-xs font-semibold"
                                                bg={BOUNTY_STATUS_TAG_COLOR[isPublished]?.bg}
                                                textColor={BOUNTY_STATUS_TAG_COLOR[isPublished]?.text}
                                                text={isPublished}
                                            />
                                        </div>
                                        {(bounty.status === 'Live and Published' ||
                                            bounty.status === 'Live and Unpublished') && (
                                            <div className="">
                                                <p className="font-semibold text-secondaryInput">
                                                    {isPublished === 'Live and Published' ? 'Unpublish' : 'Publish'}
                                                </p>
                                                <Toggle
                                                    valuee={isPublished === 'Live and Published' ? true : false}
                                                    onChangeFn={async () => {
                                                        if (isPublished === 'Live and Published') {
                                                            setIsPublished('Live and Unpublished');
                                                        }
                                                        if (isPublished === 'Live and Unpublished') {
                                                            setIsPublished('Live and Published');
                                                        }

                                                        try {
                                                            const res = await publishOrUnpublishBounty(bounty._id);
                                                        } catch (error) {}
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-span-2">
                                    <p className="font-semibold text-secondaryInput">Bounty Description</p>
                                    <p className="text-primaryInput">{bounty.description}</p>
                                </div>
                                <div className="col-span-2 flex justify-between gap-16">
                                    <div className="mb-5 w-[50%]">
                                        <p className="text-secondaryInput font-semibold">Impact Cores</p>
                                        <div className="flex  items-center">
                                            {bounty.cores?.map((core) => coreIcons[core])}
                                        </div>
                                    </div>

                                    <div className="mb-5 w-[50%]">
                                        <p className="text-secondaryInput font-semibold">SDGs</p>
                                        <div className="grid grid-cols-3 gap-[5px]">
                                            {bounty.SDGs.map((sdg, index) => (
                                                <img key={sdg + index} className="" src={SDG_IMAGES[sdg]} alt="" />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </WhiteCard>
                            <IndividualBountySubDetails
                                microtasksDetails={bounty.microtasks}
                                rewardsTabDetails={{
                                    rewardToken: bounty.rewardToken,
                                    passThreshold: bounty.passThreshold,
                                    isAttestable: bounty.isAttestable,
                                    hasImpactCertificate: bounty.hasImpactCertificate,
                                    cores: bounty.cores,
                                    sdgs: bounty.SDGs,
                                    impactPoints: bounty.impactPoints,
                                    briefMessage: bounty.impactBrief,
                                    totalRewardedAmount: bounty?.totalRewardedAmount,
                                    withReward: bounty?.hasOwnProperty('rewardToken'),
                                }}
                                sponsorTabDetails={bounty.backer}
                                dateandtimedetails={{
                                    expirationTime: bounty.expirationTimestamp,
                                    validationDeadline: bounty?.validationTillDate,
                                    estimatedTime: bounty.estimatedCompletionTime,
                                    withReward: bounty?.hasOwnProperty('rewardToken'),

                                    maxValidationDuration: bounty?.maxValidationDuration,
                                }}
                                criteriaTabDetails={bounty.criteria}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default IndividualBounty;
