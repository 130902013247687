import Overlay from '../../ui/Overlay';
import ModalBox from '../../ui/ModalBox';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { createBounty, deleteBounty } from '../../apis/bounties/bounties';
import { toast } from 'sonner';
import { sanitizeBountyData } from '../../helpers/sanitizeBountyDetails';
import { useLoadingState } from '../../hooks/useLoader';
import Button from '../../ui/Button';
import Loader from '../../ui/Loader';
import Feedbackclose from '../../assets/icons/Feedbackclose';

const CancelBountyModal = ({ close, bountyPayload, onClickingYes, isDelete, bountyId }) => {
    const myRef = useRef({ close });
    const navigate = useNavigate();
    const { loading, startLoading, stopLoading } = useLoadingState();
    const { loading: deleting, startLoading: startDeleting, stopLoading: stopDeleting } = useLoadingState();
    const onDelete = async () => {
        try {
            startDeleting();
            const { success } = await deleteBounty(bountyId);
            if (success) {
                navigate(-1);
                toast.success('Bounty successfully deleted. ');
            }
        } catch (error) {
            console.log(error);
            toast?.error(error);
        } finally {
            close(); // Close modal after duplication
            stopDeleting();
        }
    };

    useEffect(
        function () {
            function handleClick(e) {
                if (myRef.current && !myRef.current.contains(e.target)) {
                    close();
                }
            }

            document.addEventListener('click', handleClick, true);

            return () => document.removeEventListener('click', handleClick, true);
        },

        [close]
    );

    return (
        <Overlay>
            <div
                ref={myRef}
                className="absolute rounded-lg left-[50%]     bg-white translate-x-[-50%] translate-y-[-50%] top-[50%]"
            >
                <div className={`p-6  w-[518px]`}>
                    <div>
                        <Button
                            radius="rounded-full"
                            onClick={close}
                            className=" border-0 absolute top-3  right-4 "
                            variant="tertiary"
                            size="circle"
                        >
                            <Feedbackclose />
                        </Button>
                    </div>

                    <div className="w-[100%]">
                        <p className="text-xl w-[90%] text-primaryInput">
                            Are you sure you want to <span className="font-bold">leave this page ?</span>
                            <span className="font-bold">Progress will be saved</span> if you leave
                        </p>
                    </div>

                    <div className="flex items-center mt-8 gap-3">
                        <Button
                            className="text-base font-semibold"
                            radius="rounded-full"
                            onClick={() => {
                                // onClickingYes();
                                close();
                                navigate(-1);
                            }}
                            size="round"
                            variant="primary"
                            type={false ? 'submit' : 'button'}
                        >
                            {loading ? <Loader /> : 'Yes'}
                        </Button>
                        <Button
                            className="text-base font-medium text-lightBlack"
                            radius="rounded-full"
                            onClick={close}
                            size="round"
                            variant="tertiary"
                        >
                            Cancel
                        </Button>
                        {isDelete && <p>or</p>}
                        {isDelete && (
                            <Button
                                className="text-base font-semibold"
                                radius="rounded-full"
                                onClick={() => {
                                    onDelete();
                                    // close();
                                }}
                                size="round"
                                variant="primaryDelete"
                                type={'button'}
                            >
                                {deleting ? <Loader /> : 'Delete'}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </Overlay>
    );
};

export default CancelBountyModal;
