import { toast } from 'sonner';
import { BASE_URL } from '../../constants/apiPaths';
import { auth, getAccessToken } from '../../firebase';
import axios from 'axios';

const getConfig = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};
// GET /organisation/event/bounty?eventId=<eventId></eventId>
export const getEvents = async (id) => {
    try {
        const url = `${BASE_URL}/organisation/event?organisationId=${id}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
export const getBountiesInEvent = async (eventId, id) => {
    try {
        const url = `${BASE_URL}/organisation/event/bounty?eventId=${eventId}&organisationId=${id}`;
        // const url = `${BASE_URL}/organisation/event/bounty?eventId=${eventId}`;
        const accessToken = await getAccessToken();
        const response = await axios.get(url, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};

export const toggleOnboardBounty = async (id, eventId, isOnboardingBounty) => {
    try {
        const rawUrl = !isOnboardingBounty
            ? `${BASE_URL}/event/onboardingBounty/${eventId}?bounty=${id}`
            : `${BASE_URL}/event/onboardingBounty/${eventId}`;

        const url = rawUrl;
        const accessToken = await getAccessToken();
        const response = await axios.patch(url, {}, getConfig(accessToken));

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            return response; // or throw an error, depending on your error handling strategy
        }
    } catch (error) {
        return error;
    }
};
