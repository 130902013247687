import React, { useState } from 'react';
import Stepper from './Stepper';

const PopOver = ({
    content,
    rounded,
    className,
    bg,
    textColor,
    textClassName,
    text,
    borderColor,
    active,
    requestedFunding,
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleMouseEnter = () => setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);

    return (
        <div className="relative  flex items-center">
            <p className={`${rounded} flex items-center w-fit    ${bg} ${textColor}  ${className}`}>
                <span
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    data-popover-target="popover-right"
                    data-popover-placement="right"
                    className={`${textClassName} cursor-pointer `}
                >
                    {text}
                </span>{' '}
            </p>

            <div
                role="tooltip"
                className={`absolute z-10 w-[340px] text-sm pl-10 pr-6 py-4  transition-opacity duration-300 bg-white border border-borderColor rounded-lg shadow-sm  ${
                    isVisible ? 'opacity-100 visible' : 'opacity-0 invisible'
                }`}
                style={{
                    top: '50%',
                    left: '100%',
                    transform: 'translateY(-50%)',
                }}
            >
                <Stepper
                    requestedFunding={requestedFunding}
                    fillColor={'rgba(64, 64, 64, 0.6)'}
                    rounded={rounded}
                    className={className}
                    bg={bg}
                    textColor={textColor}
                    textClassName={textClassName}
                    borderColor={borderColor}
                    text={text}
                    active={active}
                />
            </div>
        </div>
    );
};

export default PopOver;
