import { parseUnits, Interface, ethers } from 'ethers';
import { saveTransactionHashAtDb } from '../apis/validator/validating';

export const fetchChainDetails = async (chainId) => {
    try {
        // Fetch the list of all chains
        const response = await fetch('https://chainid.network/chains.json');
        const chains = await response.json();

        // Find the chain matching the targetChainId
        const chainDetails = chains.find((chain) => chain.chainId === Number(chainId));

        if (!chainDetails) {
            throw new Error(`Chain with ID ${chainId} not found.`);
        }

        // Extract necessary details
        return {
            chainId: `0x${Number(chainDetails.chainId).toString(16)}`, // Convert to hex
            rpcUrls: chainDetails.rpc, // Array of RPC URLs
            chainName: chainDetails.name,
            nativeCurrency: {
                name: chainDetails.nativeCurrency.name,
                symbol: chainDetails.nativeCurrency.symbol,
                decimals: chainDetails.nativeCurrency.decimals || 18,
            },
            blockExplorerUrls: chainDetails.explorers?.map((explorer) => explorer.url) || [],
        };
    } catch (error) {
        console.error('Error fetching chain details:', error);
        throw new Error('Failed to fetch chain details.');
    }
};

export const getMetaMaskProvider = () => {
    if (window.ethereum && window.ethereum.providers) {
        // Multiple providers detected
        const providers = window.ethereum.providers;

        // Find MetaMask's provider
        const metaMaskProvider = providers.find((provider) => provider.isMetaMask);

        return metaMaskProvider;
    } else if (window.ethereum && window.ethereum.isMetaMask) {
        // Single provider detected, and it's MetaMask
        return window.ethereum;
    } else {
        // MetaMask is not installed
        return null;
    }
};

export const getERC20Balance = async (tokenAddress, walletAddress, chainId) => {
    // Connect to the blockchain
    const provider = ethers.getDefaultProvider(Number(chainId), {
        alchemy: process.env.ALCHEMY_API_KEY,
        quorum: 1,
    });

    // const provider = getMetaMaskProvider();
    // ABI for ERC20 `balanceOf` and `decimals` functions
    const ERC20_ABI = [
        'function balanceOf(address _owner) view returns (uint256)',
        'function decimals() view returns (uint8)',
    ];

    // Create a contract instance
    const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);

    try {
        // Get the token balance

        const rawBalance = await contract.balanceOf(walletAddress);

        // Get the token decimals
        const decimals = await contract.decimals();

        // Convert balance to a human-readable format
        const balance = Number(rawBalance) / Math.pow(10, Number(decimals));

        return balance;
    } catch (error) {
        throw error;
    }
};

// Example usage
// (async () => {
//   const chainId = 11155420;
//   const tokenAddress = "0x7a515022eC0a175dB9600E6E11461e031eaF8c95"; // Replace with the ERC20 token address
//   const walletAddress = "0xf8663881CcC3396776BB15671ee997B020AE3706"; // Replace with the wallet address

//   try {
//     const balance = await getERC20Balance(tokenAddress, walletAddress, chainId);
//     console.log("Token Balance:", balance);
//   } catch (error) {
//     console.error("Error:", error);
//   }
// })();

export const finaliseGrading = async ({
    tokenAddress,
    rewardsDistribution,
    provider,
    decimals,
    sdk,
    wallet,
    platformFee,
    bountyId,
    chainId,
}) => {
    const metaMaskProvider = getMetaMaskProvider();
    const transactionHashes = [];
    const rewardsArray = [...rewardsDistribution];
    try {
        if (platformFee > 0) rewardsArray.push({ evmAddress: wallet, rewardAmount: platformFee });
        for (let i = 0; i < rewardsArray.length; i++) {
            const transferAmount = parseUnits(rewardsArray[i]?.rewardAmount?.toString(), decimals); // Adjust decimals if needed (e.g., 6 for USDC)

            const accounts = await metaMaskProvider.request({ method: 'eth_requestAccounts' });
            const from = accounts[0];
            const transferData = new Interface([
                'function transfer(address to, uint256 value) public returns (bool)',
            ]).encodeFunctionData('transfer', [rewardsArray[i]?.evmAddress, transferAmount]);

            const transactionParams = {
                to: tokenAddress, // ERC-20 contract address
                from: from, // Fetch connected account
                data: transferData, // Encoded function call
                value: '0x0', // No ETH transfer
            };

            // Send transaction
            const result = await metaMaskProvider.request({
                method: 'eth_sendTransaction',
                params: [transactionParams],
            });
            transactionHashes.push(result);
        }
    } catch (error) {
        console.error('Error during transfer:', error);
        return { success: false, code: error?.message };
    }

    try {
        const res = await saveTransactionHashAtDb({
            bountyId: bountyId,
            chainId: chainId,
            transactionHashes: transactionHashes,
        });
    } catch (error) {
        return error;
    }

    return { success: true };
};

export const fundBounty = async ({ tokenAddress, receiver, amount, decimals }) => {
    const metaMaskProvider = getMetaMaskProvider();
    try {
        const transferAmount = parseUnits(amount?.toString(), decimals); // Adjust decimals if needed (e.g., 6 for USDC)

        const accounts = await metaMaskProvider.request({ method: 'eth_requestAccounts' });
        const from = accounts[0];
        const transferData = new Interface([
            'function transfer(address to, uint256 value) public returns (bool)',
        ]).encodeFunctionData('transfer', [receiver, transferAmount]);

        const transactionParams = {
            to: tokenAddress, // ERC-20 contract address
            from: from, // Fetch connected account
            data: transferData, // Encoded function call
            value: '0x0', // No ETH transfer
        };

        // Send transaction
        const result = await metaMaskProvider.request({
            method: 'eth_sendTransaction',
            params: [transactionParams],
        });
        return { success: true, transactionHash: result };
        // result will be the txn hash used in the API call
    } catch (error) {
        console.error('Error during transfer:', error);
        return { success: false, code: error?.message };
    }
};
