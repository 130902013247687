import { useController } from 'react-hook-form';

export const useStepFourForLandscapes = (control) => {
    const { field: walletReceiveField } = useController({
        name: 'walletReceive',
        control,
        rules: {
            required: 'Please enter a wallet address',
        },
    });

    const { field: fundingRequiredByField } = useController({
        name: 'fundingRequiredBy',
        control,
        rules: {
            required: 'Please enter a date',
        },
    });

    return { walletReceiveField, fundingRequiredByField };
};

// rules: {
//     required: withRewardTokenField?.value ? 'Please enter a date' : false,
// },
