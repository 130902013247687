import mobileFrame from '../../../assets/mobileFrame.png';
import Heading from '../../../ui/Heading';
import SubHeading from '../../../ui/SubHeading';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import Button from '../../../ui/Button';
import { useStepOneInputs } from '../bountiesHooks/useStepOneInputs';
import { useStepFourInputs } from '../bountiesHooks/useStepFourInputs';
// import { useStepFiveInputs } from '../bountiesHooks/useStepFiveInputs';
import dummyImage from '../../../assets/dummyImage.png';
import { converttagsstringinstring } from '../../../helpers/conerttagstringinstring';
import LinkRenderer from '../../../ui/LinkRenderer';
import BountyRadiusIcon from '../../../assets/icons/BountyRadiusIcon';
import BountyTakenTimeIcon from '../../../assets/icons/BountyTakenTimeIcon';
import DefaultSymbol from '../../../assets/icons/DefaultSymbol';
import trophy from '../../../assets/trophy.png';
import drop from '../../../assets/impactDrop.png';
import { useStepSixInputs } from '../bountiesHooks/useStepSixInputs';
import BackerImageIcon from '../../../assets/icons/BackerImageIcon';
import { bountyTypesWithIconData } from '../../../constants/datas';
import { Spa } from '@mui/icons-material';
import LoaderWhite from '../../../ui/LoaderWhite';
import { useWatch } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { formatNumber } from '../../../helpers/formatNumber';
import { toNormalRepresentation } from '../../../helpers/convertIntoDecimals';
function PreviewRewards({
    control,
    criterias,
    descriptionLinkPayload,
    icImage,
    impactPointsData,
    icFetching,
    radiusPreview,
    isValidate,
}) {
    const { titleField, descriptionField, projectIdField, typeField, dominantCoreField, modeField } =
        useStepOneInputs(control);
    const cardRef = useRef(null); // Ref for the specific card
    const topRef = useRef(null); // Ref for the top of the component
    const { expirationTimeField, dayField, hoursField, minutesField } = useStepFourInputs(control, isValidate);
    // const {
    //     backerLogoField,
    //     backerMessageField,
    //     backerNameField,
    //     initiatorLogoField,
    //     initiatorMessageField,
    //     initiatorNameField,
    // } = useStepFiveInputs(control);
    const {
        tokenField,
        hasImpactCertificateField,
        totalRewardAmountField,
        distributionModeField,

        noOfWinnersField,
    } = useStepSixInputs(control);

    useEffect(() => {
        if (hasImpactCertificateField.value) {
            // Scroll to specific card when toggledVariable is true
            cardRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            // Scroll to top of the component when toggledVariable is false
            topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [hasImpactCertificateField.value]); // Runs every time toggledVariable changes
    const watchedRanks = useWatch({ name: 'ranks', control });
    const { symbol, image } = tokenField?.value ? tokenField.value : {};

    const dateObject = expirationTimeField.value && new Date(expirationTimeField.value);

    const ranks = watchedRanks?.filter((i) => i.amount)?.map((obj) => Number(obj.amount));

    const tokenAmountDisplay = !ranks
        ? 0
        : distributionModeField?.value === 'Rank based distribution'
        ? Math.max(...ranks)
        : noOfWinnersField?.value
        ? Number(totalRewardAmountField.value) / noOfWinnersField.value
        : 0;

    console.log(icFetching, 'see is fetching or not');
    return (
        <div className="">
            <div
                ref={topRef}
                className="relative w-[300px] h-[610px]     "
                style={{
                    backgroundImage: `url(${mobileFrame})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <main className="absolute  top-28 left-6 right-6 h-[70%] overflow-auto main">
                    <div>
                        <Heading type={'xxsmall'} className={'text-lightBlack'}>
                            {titleField.value || '[Bounty Title] appears here '}
                        </Heading>
                        {/* <p className="text-xs  mt-2 break-words ">{descriptionField.value || 'Description'}</p> */}
                        <LinkRenderer text={descriptionField.value} links={descriptionLinkPayload} />
                    </div>
                    <div className="mt-2 grid grid-cols-2 items-end gap-2  ">
                        <div className="">
                            <SubHeading className={'text-minerDescription'} type={'xxsmall'}>
                                Bounty Type
                            </SubHeading>
                            <div className="flex flex-col gap-1 mt-1">
                                <p className="bg-previewLight border flex gap-[6px] items-center justify-center text-xs py-1 text-center rounded-3xl border-previewMedium text-previewDark ">
                                    <span>
                                        {
                                            bountyTypesWithIconData?.find(
                                                (obj) => obj.label?.toLowerCase() === typeField.value?.toLowerCase()
                                            )?.icon
                                        }
                                    </span>{' '}
                                    <span>{typeField.value || '-'}</span>{' '}
                                </p>
                            </div>
                        </div>
                        <div className="my-2 col-span-2    ">
                            <SubHeading type={'xxsmall'}>Sponsored By</SubHeading>
                            <div className="flex gap-2 mt-1">
                                <BackerImageIcon />

                                <p className="text-xs uppercase font-semibold  text-minerTitle">{'Sponsor Name'}</p>
                            </div>
                        </div>
                        {/* {backerNameField?.value && (
                            <div className="my-2 col-span-2    ">
                                <SubHeading type={'xxsmall'}>Sponsored By</SubHeading>
                                <div className="flex gap-2 mt-1">
                                    {backerLogoField.value ? (
                                        <img
                                            className="w-[17px] h-[17px] rounded-full"
                                            src={backerLogoField.value}
                                            alt=""
                                        />
                                    ) : (
                                        // <img className="w-6 h-6 rounded-full" src={dummyImage} alt="" />
                                        <BackerImageIcon />
                                    )}
                                    <p className="text-xs uppercase font-semibold  text-minerTitle">
                                        {backerNameField.value || 'Sponsor Name'}
                                    </p>
                                </div>
                            </div>
                        )} */}
                    </div>
                    <div className="flex gap-2 my-2 ">
                        <div className="border w-[50%] rounded-md border-borderColor py-2 px-3">
                            <p className="text-xs">{tokenField?.value?.name || 'Token Name'}</p>
                            <div className="flex items-center gap-1">
                                {tokenField?.value?.image ? (
                                    <img className="w-3 h-3 rounded-full" src={tokenField?.value?.image} alt="" />
                                ) : (
                                    <DefaultSymbol />
                                )}
                                <p>
                                    {tokenAmountDisplay === -Infinity
                                        ? '00'
                                        : tokenAmountDisplay
                                        ? toNormalRepresentation(tokenAmountDisplay)
                                        : '00'}
                                </p>
                            </div>
                        </div>
                        <div className="border w-[50%] rounded-md border-borderColor py-2 px-3 ">
                            <p className="text-xs">Impact Points</p>
                            <div className="flex items-center gap-1">
                                <img className="w-3 h-3" src={trophy} alt="" />
                                <p>{formatNumber(impactPointsData) || '00'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="flex items-center justify-between gap-2 my-2 ">
                            <img className="w-3 h-3 object-contain " src={drop} alt="" />

                            <SubHeading
                                className={`w-[90%] ${hasImpactCertificateField.value ? 'text-[#1A56DB]' : ''} `}
                                type={'xxsmall'}
                            >
                                {' '}
                                Impact certificate{' '}
                                {hasImpactCertificateField.value ? <span className="">is included</span> : '_ _ _ _'}
                            </SubHeading>
                        </div>
                        <div className="flex items-center justify-between gap-2 my-2 ">
                            <span>
                                <CalendarIcon />
                            </span>
                            <SubHeading className={'w-[90%]'} type={'xxsmall'}>
                                {' '}
                                Bounty available till{' '}
                                <span>
                                    {dateObject?.toLocaleDateString()
                                        ? dateObject?.toLocaleDateString([], {
                                              hour: '2-digit',
                                              minute: '2-digit',
                                              hour12: true, // Use 12-hour format with AM/PM
                                          })
                                        : ' _ '}
                                </span>
                            </SubHeading>
                        </div>
                        {modeField.value === 'physical' && (
                            <div className="flex items-center justify-between gap-2 my-2 ">
                                <span>
                                    <BountyRadiusIcon />
                                </span>

                                <SubHeading className={'w-[90%]'} type={'xxsmall'}>
                                    Bounty Radius is {radiusPreview ? radiusPreview + ' Kms' : '_ _ _ _'}
                                </SubHeading>
                            </div>
                        )}

                        <div className="flex items-center justify-between gap-2 my-2  ">
                            <span>
                                <BountyTakenTimeIcon />
                            </span>
                            <SubHeading className={'w-[90%]'} type={'xxsmall'}>
                                {' '}
                                Typically this bounty takes
                                {dayField?.value > 0 ? <span>{` ${dayField?.value} days `}</span> : ' _ '}
                                {hoursField?.value > 0 ? <span>{` ${hoursField?.value} hours `}</span> : ' _ '}
                                {minutesField?.value > 0 ? <span>{` ${minutesField?.value} minutes `}</span> : ' _ '} to
                                complete
                            </SubHeading>
                        </div>
                    </div>
                    {/* {backerMessageField?.value && (
                        <div className="flex gap-2 my-2 border border-borderColor px-1 py-2 rounded-md ">
                            {backerLogoField.value ? (
                                <img className="w-[17px] h-[17px] rounded-full" src={backerLogoField.value} alt="" />
                            ) : (
                                // <img className="w-6 h-6 rounded-full" src={dummyImage} alt="" />
                                <BackerImageIcon />
                            )}
                            <p className="text-xs text-minerSecondary">
                                {backerMessageField.value || 'Enter sponsor message (optional)'}
                            </p>
                        </div>
                    )} */}
                    <div className="flex gap-2 my-2 border border-borderColor px-1 py-2 rounded-md ">
                        <BackerImageIcon />

                        <p className="text-xs text-minerSecondary">{'Enter sponsor message (optional)'}</p>
                    </div>
                    <div>
                        <Heading type={'xxsmall'} className={'text-lightBlack my-1'}>
                            Criteria for completion
                        </Heading>
                        <ul className="flex flex-col gap-1">
                            {criterias
                                .filter((i) => i.criteriaName)
                                .map((criteria, index) => {
                                    return (
                                        <li
                                            key={criteria + index}
                                            className="text-xs flex items-start gap-2 break-words "
                                        >
                                            <span className="bg-previewLight font-medium rounded-full w-5 h-5 flex items-center justify-center border border-previewMedium text-previewDark">
                                                {index + 1}
                                            </span>
                                            <p className="break-words w-[80%]  ">{criteria.criteriaName}</p>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </main>

                {/* <footer className="absolute bottom-6 left-6 right-6">
                    <Button variant="preview" size="roundPreview">
                        Add Deliverables & Tips
                    </Button>
                </footer> */}
            </div>
            {hasImpactCertificateField.value && (
                <div
                    ref={cardRef}
                    className="mt-14 w-[320px] h-[362px] bg-[#000000] flex items-center justify-center overflow-hidden rounded-md "
                >
                    {!icFetching && !icImage?.sampleImage ? (
                        <div className="w-[157px]">
                            <p className="text-white text-center ">Please fill all details to see the preview</p>
                        </div>
                    ) : icFetching ? (
                        <LoaderWhite />
                    ) : (
                        <img className="w-full h-full" src={icImage.sampleImage} alt="" />
                    )}
                </div>
            )}
        </div>
    );
}

export default PreviewRewards;
